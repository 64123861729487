
import { defineComponent, ref } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import moment from "moment";
import { useI18n } from "vue-i18n";

interface Exercise {
  id: string;
  createdAt: string;
  status: string;
  reviewDate: string;
  reviewNote: string;
}

export default defineComponent({
  name: "month-exercises",
  props: {
    title: String,
    description: String,
    color: String,
    height: Number,
    exercises: Array,
  },
  components: {},
  setup(props) {
    const { t } = useI18n();
    const height = ref(props.height);

    const color = ref(props.color);

    const labelColor = getCSSVariableValue("--bs-" + "gray-800");
    const lightColor = getCSSVariableValue("--bs-light-" + color.value);

    const successColor = getCSSVariableValue("--bs-success");
    const dangerColor = getCSSVariableValue("--bs-danger");

    const exercises: Exercise[] = props.exercises as Exercise[];
    const currentDate = new Date();
    const today = new Date().getDate();
    const monthName = moment().format("MMMM");

    const currentMonthExercises = exercises.filter((exercise: Exercise) => {
      if (exercise.reviewDate) {
        const reviewDate = new Date(exercise.reviewDate);
        return (
          reviewDate.getMonth() == currentDate.getMonth() &&
          reviewDate.getFullYear() == currentDate.getFullYear() &&
          reviewDate.getDate() <= today
        );
      }
    });

    const days: string[] = [];
    for (let i = 1; i <= today; i++) days.push(i + " " + monthName);

    const validatedSerie: number[] = [];
    const rejectedSerie: number[] = [];

    for (let i = 1; i <= today; i++) {
      validatedSerie.push(
        currentMonthExercises.filter(
          (exercise: Exercise) =>
            new Date(exercise.reviewDate).getDate() == i &&
            exercise.status == "validated"
        ).length
      );

      rejectedSerie.push(
        currentMonthExercises.filter(
          (exercise: Exercise) =>
            new Date(exercise.reviewDate).getDate() == i &&
            exercise.status == "rejected"
        ).length
      );
    }

    const colors = [successColor, dangerColor];

    const chartOptions = {
      chart: {
        fontFamily: "inherit",
        type: "area",
        height: height.value,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
        sparkline: {
          enabled: false,
        },
      },
      plotOptions: {},
      legend: {
        show: true,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        show: true,
        width: 3,
        colors,
      },
      xaxis: {
        categories: days,
        axisBorder: {
          show: true,
        },
        axisTicks: {
          show: true,
        },
        labels: {
          show: false,
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
        },
        crosshairs: {
          show: false,
          position: "front",
          stroke: {
            color: "#E4E6EF",
            width: 1,
            dashArray: 3,
          },
        },
        tooltip: {
          enabled: true,
        },
      },
      yaxis: {
        min: 0,
        max: Math.max(...validatedSerie, ...rejectedSerie),
        labels: {
          show: true,
          style: {
            colors: labelColor,
            fontSize: "12px",
          },
          formatter: function (val) {
            return val.toFixed(0);
          },
        },
      },
      tooltip: {
        style: {
          fontSize: "12px",
        },
        y: {
          formatter: function (val) {
            return val + " " + t("course.exercise");
          },
        },
      },
      fill: {
        type: "gradient",
        gradient: {
          stops: [0, 100],
        },
      },
      colors,
      markers: {
        colors,
        strokeColor: [lightColor],
        strokeWidth: 3,
      },
    };

    const series = [
      {
        name: t("course.validatedFilter"),
        data: validatedSerie,
      },
      {
        name: t("course.rejectedFilter"),
        data: rejectedSerie,
      },
    ];

    return {
      chartOptions,
      series,
      t,
      currentMonthExercises,
    };
  },
});
